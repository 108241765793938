// We haven't added icon's computed property because it makes this mixin coupled with UI
export const togglePasswordConfirmVisibility = {
  data() {
    return {
      passwordConfirmFieldType: 'password',
    };
  },
  methods: {
    togglePasswordConfirmVisibility() {
      this.passwordConfirmFieldType = this.passwordConfirmFieldType === 'password' ? 'text' : 'password';
    },
  },
};

export const toggleNewPasswordVisibility = {
  data() {
    return {
      newPasswordFieldType: 'password',
    };
  },
  methods: {
    toggleNewPasswordVisibility() {
      this.newPasswordFieldType = this.newPasswordFieldType === 'password' ? 'text' : 'password';
    },
  },
};

export const _ = null;
