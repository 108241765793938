import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAnalytics(ctx, params) {
      return useJwt.getHostAnalytics(params).then(response => response);
    },
    fetchHosts(ctx, params) {
      return useJwt.getHosts(params).then(response => response);
    },
    getHostDetail(ctx, id) {
      return useJwt.getHostDetail(id).then(response => response);
    },
    updateHostSubscription(ctx, data) {
      return useJwt.updateHostSubscription(data).then(response => response);
    },
    changeHostPassword(ctx, data) {
      return useJwt.changeHostPassword(data).then(response => response);
    },
    createHost(ctx, data) {
      return useJwt.createHost(data).then(response => response);
    },
    updateHost(ctx, data) {
      return useJwt.updateHost(data).then(response => response);
    },
    importHosts(ctx, data) {
      return useJwt.importHosts(data).then(response => response);
    },
    exportHosts(ctx, data) {
      return useJwt.exportHosts(data).then(response => response);
    },
    deleteHosts(ctx, data) {
      return useJwt.deleteHosts(data).then(response => response);
    },
    syncHost(ctx, data) {
      return useJwt.syncHostDataFromItro(data).then(response => response);
    },
  },
};
